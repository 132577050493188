import {APIProvider, Map, Pin, useMap} from '@vis.gl/react-google-maps';
import {DataStatus} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import {useTheme} from 'styled-components';

import {useCallback, useEffect, useMemo, useState} from 'react';

import {isNil, isNotNil} from 'ramda';

import {environment} from '@dms/environment';

import {PriceReportType} from '@price-report/shared';

import {RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {useFilter} from '../../../../../hooks/FilterContext';
import {ExtendedStatisticsCar} from '../types';
import {getChartItemColor} from '../utils/getChartItemColor';
import {AdvancedMarkerWithInfoWindow} from './AdvancedMarkerWithInfoWindow';
import {CarTooltip} from './CarTooltip';
import {Circle} from './Circle';

const DEFAULT_COORDS = {lat: 50.0755381, lng: 14.4378005};

interface MapViewProps extends RequiredTestIdProps {
  priceReport: PriceReportType;
  data: ExtendedStatisticsCar[];
  selectedVehicleId: string | null;
  setSelectedVehicleId: (id: string | null) => void;
}

export function MapView(props: MapViewProps) {
  const [isLoading, , setIsLoaded] = useBoolean(true);
  return (
    <APIProvider apiKey={environment.GOOGLE_API_KEY} onLoad={setIsLoaded}>
      <MapViewComponent {...props} isLoading={isLoading} />
    </APIProvider>
  );
}

interface MapViewComponentProps extends MapViewProps {
  isLoading: boolean;
}

function MapViewComponent(props: MapViewComponentProps) {
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(null);
  const {filter} = useFilter();
  const theme = useTheme();
  const map = useMap();

  const location = useMemo(
    () =>
      isNotNil(filter?.location) ? {lat: filter.location.lat, lng: filter.location.lon} : undefined,
    [filter]
  );
  const radius = isNotNil(filter?.location) ? filter.location.distance * 1000 : undefined;
  const carsWithLocation = useMemo(
    () => props.data.filter((car) => isNotNil(car.location)),
    [props.data]
  );

  const getColor = (car: ExtendedStatisticsCar, index: number) =>
    getChartItemColor(
      theme,
      car,
      activeTooltipIndex === index,
      props.selectedVehicleId === car.ad_id
    );

  const onClick = useCallback(
    (car: ExtendedStatisticsCar) =>
      props.setSelectedVehicleId(props.selectedVehicleId === car.ad_id ? null : car.ad_id),
    [props]
  );

  useEffect(() => {
    if (isNotNil(map)) {
      let bounds: google.maps.LatLngBounds | null = null;
      if (isNotNil(location) && isNotNil(radius)) {
        const circle = new google.maps.Circle({
          center: location,
          radius,
        });
        bounds = circle.getBounds();
      } else if (carsWithLocation.length > 0) {
        bounds = new google.maps.LatLngBounds();
        carsWithLocation.forEach((car) => {
          if (isNotNil(car.location)) {
            bounds?.extend({lat: car.location.lat, lng: car.location.lon});
          }
        });
      }

      if (isNotNil(bounds)) {
        map.fitBounds(bounds);
      }
    }
  }, [carsWithLocation, location, map, radius]);

  return (
    <DataStatus isLoading={props.isLoading}>
      <Box position="absolute" height="100%" width="100%">
        <Map
          mapId="google-map-script"
          defaultZoom={isNil(location) ? 6 : 10}
          defaultCenter={location ?? DEFAULT_COORDS}
        >
          {carsWithLocation.map((car, index) => (
            <Show key={car.ad_id} when={isNotNil(car.location)}>
              <AdvancedMarkerWithInfoWindow
                position={{lat: car.location?.lat ?? 0, lng: car.location?.lon ?? 0}}
                onClick={() => onClick(car)}
                onMouseEnter={() => setActiveTooltipIndex(index)}
                onMouseLeave={() => setActiveTooltipIndex(null)}
                infoContent={
                  <CarTooltip
                    adId={car.ad_id}
                    isMyVehicle={car.isMyVehicle}
                    priceReport={props.priceReport}
                    data-testid={suffixTestId('carTooltip', props)}
                  />
                }
              >
                <Pin background={getColor(car, index)} glyphColor="#000" borderColor="#000" />
              </AdvancedMarkerWithInfoWindow>
            </Show>
          ))}
          <Show when={isNotNil(location) && isNotNil(radius)}>
            <Circle
              radius={radius}
              center={location}
              strokeColor={theme.colors.general.accent}
              fillColor={theme.colors.general.accent}
              fillOpacity={0.1}
            />
          </Show>
        </Map>
      </Box>
    </DataStatus>
  );
}
