import {
  AdvancedMarker,
  AdvancedMarkerProps,
  InfoWindow,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import {Show} from 'platform/foundation';

import {ReactNode} from 'react';

import {useBoolean} from 'shared';

export function AdvancedMarkerWithInfoWindow(
  props: AdvancedMarkerProps & {infoContent: ReactNode}
) {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [isInfoWindowShown, showInfoWindow, hideInfoWindow] = useBoolean(false);

  return (
    <>
      <AdvancedMarker
        {...props}
        ref={markerRef}
        onMouseEnter={(event) => {
          showInfoWindow();
          props.onMouseEnter?.(event);
        }}
        onMouseLeave={(event) => {
          hideInfoWindow();
          props.onMouseLeave?.(event);
        }}
      >
        {props.children}
      </AdvancedMarker>

      <Show when={isInfoWindowShown}>
        {/* @ts-ignore headerDisabled actually works */}
        <InfoWindow anchor={marker} headerDisabled>
          {props.infoContent}
        </InfoWindow>
      </Show>
    </>
  );
}
