import {match, Pattern} from 'ts-pattern';

import {ExtendedStatisticsCar} from '../types';
import {ChartVariant} from '../types/ChartVariant';

export const getYPosition = (car: ExtendedStatisticsCar, chartVariant: ChartVariant) =>
  match([chartVariant, !!car.isDebugVehicle])
    .with(['priceMap', false], () => car.original_price_with_vat)
    .with(['priceMap', true], () => car.price_with_vat)
    .with(['price', Pattern.any], () => car.original_price_with_vat)
    .with(['mileage', Pattern.any], () => car.mileage)
    .with(['daysOnStock', Pattern.any], () => car.days_on_stock)
    .with(['featuresLevel', Pattern.any], () => Math.floor(car.feature_score * 100))
    .with(['mapView', Pattern.any], () => 0)
    .exhaustive();
