/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
export enum featureFlags {
  PRICE_REPORT_DRILL_DOWN = 'pricereport_drilldown',
  PRICE_REPORT_VAT_CHART = 'pricereport_vat_chart',
  PR_FILTERS_GEOLOCATION = 'pr_filters_geolocation',
  PR_MAP_VIEW = 'pr_map_view',
}
/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
