import {TooltipProps} from 'recharts';

import {PriceReportType} from '@price-report/shared';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {StatisticsChartItem} from '../types';
import {CarTooltip} from './CarTooltip';

export function ChartTooltip(
  // TooltipProps comes from recharts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: TooltipProps<any, any> & {priceReport: PriceReportType} & RequiredTestIdProps
) {
  const payload: StatisticsChartItem | Nullish = props.payload?.[0]?.payload;

  return (
    <CarTooltip
      adId={payload?.car.ad_id}
      isMyVehicle={payload?.car.isMyVehicle}
      priceReport={props.priceReport}
      data-testid={suffixTestId('carTooltip', props)}
    />
  );
}
